<template>
  <v-card
    v-if="field.value"
    class="elevation-0"
  >
    <v-alert
      color="info"
      icon="info"
      value="true"
    >
      <h4>{{ field.value }}</h4>
      <p>{{ field.info }}</p>
    </v-alert>
  </v-card>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
}
</script>
